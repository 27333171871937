<template>
  <b-modal
    :id="`select-type-create-certificate-modal_${item.id}`"
    v-model="$show"
    centered
    no-close-on-backdrop
    size="lg"
    title="กรุณาเลือกตำแหน่งผู้ลงนาม"
    @hidden="onHidden"
  >
    <div class="px-3">
      <b-row>
        <b-form-group
          label="ตัวเลือกการออกใบรับรอง"
          v-slot="{ ariaDescribedby }"
          class="ml-3 font-weight-bold ft-s-14"
        >
          <b-form-radio-group
            id="radio-group-transaction-type"
            v-model="selectedCertSize"
            :options="certSizes"
            text-field="name"
            value-field="name"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>
      </b-row>
      <br />
      <b-row v-if="selectedCertSize !== ''">
        <h4 class="col-12 font-weight-bold">ชื่อผู้ลงนาม</h4>
        <div class="col-md-4 col-12 py-2">
          <label class="font-weight-bold" for="rank">คำนำหน้า</label>
          <b-form-input
            type="text"
            id="rank"
            v-model="selected"
            :state="isSubmiting ? (rank ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="rank-feedback">
            กรุณากรอกคำนำหน้า
          </b-form-invalid-feedback>
          <!-- <v-select
            :id="isSubmiting ? (rank ? 'rank' : 'rq_rank') : 'rank'"
            v-model="rank"
            :options="ranks"
            :loading="isFetchingRank"
            label="m_rank_name"
            :reduce="(rank) => rank.m_rank_name"
          ></v-select>
          <small v-if="isSubmiting && !rank" class="text-danger">
            กรุณาเลือกยศ
          </small> -->
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-first_name">ชื่อ</label>
          <b-form-input
            type="text"
            id="text-first_name"
            v-model="first_name"
            :state="isSubmiting ? (first_name ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-first_name-feedback">
            กรุณากรอกชื่อ
          </b-form-invalid-feedback>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-last_name">นามสกุล</label>
          <b-form-input
            type="text"
            id="text-last_name"
            v-model="last_name"
            :state="isSubmiting ? (last_name ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-last_name-feedback">
            กรุณากรอกนามสกุล
          </b-form-invalid-feedback>
        </div>
      </b-row>
    </div>

    <div class="col-12 p-3" v-if="selectedCertSize !== ''">
      <h4 class="font-weight-bold mt-2">ตำแหน่งผู้ลงนาม</h4>
      <b-form-input
        type="text"
        id="text-last_name"
        v-model="rank"
        placeholder="ตัวอย่าง: ผู้บังคับการ ตำรวจภูธรจังหวัดกาญจนบุรี"
        :state="isSubmiting ? (selected ? null : false) : null"
      ></b-form-input>
      <b-form-invalid-feedback id="text-last_name-feedback">
        กรุณากรอกข้อมูลผู้ลงนาม
      </b-form-invalid-feedback>
    </div>

    <div v-if="selectedCertSize !== ''">
      <hr />
    </div>

    <div class="p-3" v-if="selectedCertSize == 'ผู้ลงนาม 3 คน'">
      <b-row>
        <h4 class="col-12 font-weight-bold">ชื่อผู้ลงนาม 2</h4>
        <div class="col-md-4 col-12 py-2">
          <label class="font-weight-bold" for="rank">คำนำหน้า</label>
          <b-form-input
            type="text"
            id="rank"
            v-model="selected2"
            :state="isSubmiting ? (rank ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="rank-feedback">
            กรุณากรอกคำนำหน้า
          </b-form-invalid-feedback>
          <!-- <v-select
            :id="isSubmiting ? (rank ? 'rank' : 'rq_rank') : 'rank'"
            v-model="rank"
            :options="ranks"
            :loading="isFetchingRank"
            label="m_rank_name"
            :reduce="(rank) => rank.m_rank_name"
          ></v-select>
          <small v-if="isSubmiting && !rank" class="text-danger">
            กรุณาเลือกยศ
          </small> -->
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-first_name">ชื่อ</label>
          <b-form-input
            type="text"
            id="text-first_name"
            v-model="first_name2"
            :state="isSubmiting ? (first_name ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-first_name-feedback">
            กรุณากรอกชื่อ
          </b-form-invalid-feedback>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-last_name">นามสกุล</label>
          <b-form-input
            type="text"
            id="text-last_name"
            v-model="last_name2"
            :state="isSubmiting ? (last_name ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-last_name-feedback">
            กรุณากรอกนามสกุล
          </b-form-invalid-feedback>
        </div>
      </b-row>
    </div>

    <div class="col-12 p-3" v-if="selectedCertSize == 'ผู้ลงนาม 3 คน'">
      <h4 class="font-weight-bold mt-2">ตำแหน่งผู้ลงนาม 2</h4>
      <b-form-input
        type="text"
        id="text-last_name"
        v-model="rank2"
        placeholder="ตัวอย่าง: ผู้บังคับการ ตำรวจภูธรจังหวัดกาญจนบุรี"
        :state="isSubmiting ? (selected ? null : false) : null"
      ></b-form-input>
      <b-form-invalid-feedback id="text-last_name-feedback">
        กรุณากรอกข้อมูลผู้ลงนาม
      </b-form-invalid-feedback>
    </div>

    <div v-if="selectedCertSize == 'ผู้ลงนาม 3 คน'">
      <hr />
    </div>

    <div class="p-3 pt-4" v-if="selectedCertSize !== ''">
      <b-row>
        <h4 class="col-12 font-weight-bold">ชื่อผู้อำนวยการโรงเรียน</h4>
        <!-- <div class="col-12 py-2">
          <b-form-input
            type="text"
            id="prefix"
            placeholder="กรอกคำนำหน้าชื่อ ชื่อ-นามสกุล ที่นี่"
            v-model="director_prefix"
            :state="isSubmiting ? (director_prefix ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-first_name-feedback">
            กรุณากรอกชื่อผู้อำนวยการโรงเรียน
          </b-form-invalid-feedback>
        </div> -->

        <div class="col-md-4 col-12 py-2">
          <label class="font-weight-bold" for="director_prefix">คำนำหน้า</label>
          <b-form-input
            type="text"
            id="director_prefix"
            v-model="director_prefix"
            :state="isSubmiting ? (director_prefix ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="director_prefix-feedback">
            กรุณากรอกคำนำหน้า
          </b-form-invalid-feedback>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-director_name">ชื่อ</label>
          <b-form-input
            type="text"
            id="text-director_name"
            v-model="director_name"
            :state="isSubmiting ? (director_name ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-director_name-feedback">
            กรุณากรอกชื่อ
          </b-form-invalid-feedback>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label class="font-weight-bold" for="text-director_lastname"
            >นามสกุล</label
          >
          <b-form-input
            type="text"
            id="text-director_lastname"
            v-model="director_lastname"
            :state="isSubmiting ? (director_lastname ? null : false) : null"
          ></b-form-input>
          <b-form-invalid-feedback id="text-director_lastname-feedback">
            กรุณากรอกนามสกุล
          </b-form-invalid-feedback>
        </div>
      </b-row>
    </div>

    <div class="col-12 p-3" v-if="selectedCertSize !== ''">
      <h4 class="font-weight-bold mt-2">ตำแหน่งผู้อำนวยการโรงเรียน</h4>
      <b-form-input
        type="text"
        id="text-director-rank"
        v-model="director_rank"
        placeholder="ตัวอย่าง: ผู้อำนวยการโรงเรียน"
        :state="isSubmiting ? (director_rank ? null : false) : null"
      ></b-form-input>
      <b-form-invalid-feedback id="text-director-rank-feedback">
        กรุณากรอกข้อมูลผู้อำนวยการโรงเรียน
      </b-form-invalid-feedback>
    </div>

    <!-- <b-form-group class="d-flex flex-row my-0 ml-3">
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="ผู้กำกับการ"
        class="py-3"
        ><h5 class="font-weight-bold">ผู้กำกับการ</h5>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="ผู้บังคับการ"
        class="py-3"
        ><h5 class="font-weight-bold">ผู้บังคับการ</h5>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="สารวัตร"
        class="py-3"
        ><h5 class="font-weight-bold">สารวัตร</h5>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="สารวัตรใหญ่"
        class="py-3"
        ><h5 class="font-weight-bold">สารวัตรใหญ่</h5>
      </b-form-radio>
    </b-form-group> -->

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel()"> ยกเลิก </b-button>

      <b-button size="sm" variant="primary" @click="onSubmit">
        <!-- <b-spinner
          v-if="printingItemId && printingItemId == item.id"
          small
          variant="light"
          label="printing..."
        ></b-spinner> -->
        <span>ยืนยัน</span>
      </b-button>
    </template>

    <!-- <generate-certificate
      :ref="`pdf_${item.id}`"
      :item="item"
      :format="format"
      :type-select="selected"
      @onProgressDone="onProgressDone"
    ></generate-certificate> -->
  </b-modal>
</template>

<script>
// import GenerateCertificate from "../report/GenerateCertificate";
import { Auth, MRank } from "../../models";
// import vSelect from "vue-select";

export default {
  components: {
    // vSelect,
  },
  props: {
    show: Boolean,
    item: {
      type: Object,
      default: () => ({}),
    },
    typePrint: String,
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      selectedCertSize: "",
      isSubmiting: false,
      // format: "",
      selected: "",
      first_name: "",
      last_name: "",
      rank: "",
      isFetchingRank: false,
      director_rank: "ผู้อำนวยการโรงเรียน",
      director_prefix: this.item?.director_dares[0]?.prefix_name || "",
      director_name: this.item?.director_dares[0]?.first_name || "",
      director_lastname: this.item?.director_dares[0]?.last_name || "",
      selected2: "",
      first_name2: "",
      last_name2: "",
      rank2: "",
      certSizes: [
        {
          name: "ผู้ลงนาม 2 คน",
        },
        {
          name: "ผู้ลงนาม 3 คน",
        },
        {
          name: "แบบเก่า",
        },
      ],
      // printingItemId: null,
    };
  },

  watch: {
    item: function(data) {
      this.director_rank = "ผู้อำนวยการโรงเรียน";
      this.director_prefix = data.director_dares[0].prefix_name || "";
      this.director_name = data.director_dares[0].first_name || "";
      this.director_lastname = data.director_dares[0].last_name || "";
    },
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
    authUser() {
      return Auth.user();
    },

    ranks() {
      return MRank.all();
    },
  },

  methods: {
    onSubmit() {
      this.isSubmiting = true;
      if (!this.rank || !this.first_name || !this.last_name)
        return this.$toast.error("กรุณากรอกชื่อ-นามสกุลผู้ลงนามให้ครบถ้วน");
      if (this.selectedCertSize == "ผู้ลงนาม 3 คน") {
        if (!this.rank2 || !this.first_name2 || !this.last_name2)
          return this.$toast.error("กรุณากรอกชื่อ-นามสกุลผู้ลงนาม2 ให้ครบถ้วน");
      }

      if (
        !this.director_prefix ||
        !this.director_name ||
        !this.director_lastname
      )
        return this.$toast.error(
          "กรุณากรอกข้อมูลผู้อำนวยการโรงเรียนให้ครบถ้วน"
        );

      if (!this.selected) return this.$toast.error("กรุณากรอกข้อมูลผู้ลงนาม");
      if (this.selectedCertSize == "ผู้ลงนาม 3 คน") {
        if (!this.selected2)
          return this.$toast.error("กรุณากรอกข้อมูลผู้ลงนาม 2");
      }
      if (!this.director_rank)
        return this.$toast.error("กรุณากรอกข้อมูลผู้อำนวยการโรงเรียน");
      const title_name = `${this.selected}`
      const user_name = `${this.first_name} ${this.last_name}`;
      const user_rank = `${this.rank}`;
      const title_name2 = `${this.selected2}`;
      const user_name2 = `${this.first_name2} ${this.last_name2}`;
      const user_rank2 = `${this.rank2}`;
      const director_username = `${this.director_name} ${this.director_lastname}`;
      const title_name3 = `${this.director_prefix}`;

      this.$emit(
        "selected:type",
        this.selected,
        user_name,
        user_rank,
        director_username,
        this.director_prefix,
        this.director_rank,
        user_name2,
        user_rank2,
        this.selectedCertSize,
        title_name,
        title_name2,
        title_name3
      );
      this.$bvModal.hide(
        `select-type-create-certificate-modal_${this.item.id}`
      );
    },

    // async printCertificate(item) {
    //   // สั่ง print รายละเอียด
    //   if (!this.selected) return this.$toast.error("กรุณาเลือกตำแหน่งผู้ลงนาม");

    //   this.printingItemId = item.id;

    //   const promise = await TeachingDare.api().findOne(
    //     item.id,
    //     {},
    //     { save: false }
    //   );

    //   if (promise) {
    //     const { data = {} } = promise.response.data;

    //     this.printItem = { ...data };

    //     this.$nextTick(() => {
    //       if (this.$refs[`pdf_${item.id}`]) {
    //         this.$nextTick(() => {
    //           const { $refs } = this.$refs[`pdf_${item.id}`];

    //           $refs.html2Pdf.generatePdf();
    //         });
    //       }
    //     });
    //   } else {
    //     this.$toast.error(
    //       "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
    //     );
    //   }
    // },

    // onProgressDone() {
    //   this.printingItemId = null;
    // },

    async fetchRanks() {
      this.isFetchingRank = true;
      try {
        await MRank.api().findAll();
      } catch (error) {
        console.error(error);
      } finally {
        this.isFetchingRank = false;
      }
    },

    onHidden() {
      this.$emit("onHidden");
      this.clearData();
    },

    clearData() {
      this.rank = "";
      this.first_name = "";
      this.last_name = "";
      this.selected = "";
      this.director_prefix = "";
      this.director_name = "";
      this.director_lastname = "";
      this.isSubmiting = false;
    },
  },

  created() {
    this.fetchRanks();
  },
};
</script>

<style lang="scss" scoped>
.form-control.is-invalid {
  background-image: none !important;
}

/deep/ #rq_rank.v-select .vs__dropdown-toggle {
  border-color: #f83245 !important;
}
</style>
